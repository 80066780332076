<mat-card appearance="outlined" class="list-card">
  <mat-card-content class="process-card-content">
    <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

    <div *ngIf="!isLoading" [class.expanded-container]="processDrawer.opened" [class.collapsed-container]="!processDrawer.opened">
      <mat-drawer-container class="mat-drawer-container" [@.disabled]="true">
        <mat-drawer #processDrawer mode="side" opened="true" class="process-drawer">
          <h4 class="process-drawer-header">Processes</h4>
          <mat-tree [dataSource]="processTreeDataSource" [treeControl]="processTreeControl" class="process-drawer-tree">
            <ul>
              <mat-tree-node *matTreeNodeDef="let node">
                <li class="mat-tree-node">
                  <button mat-button
                          *ngIf="node.parentId && node.id"
                          class="button mat-drawer-tree-button"
                          [class.mat-drawer-active-tree-button]="isActiveNode(node)"
                          (click)="openProcess(node)">
                    {{node.reference ? node.reference + ' - ' + node.title : node.title}}
                  </button>
                  <button mat-button
                          *ngIf="canEdit() && node.parentId && !node.id"
                          class="button mat-drawer-tree-button"
                          (click)="newProcess(node)">
                    <mat-icon color="accent">add</mat-icon>
                    Add Process
                  </button>
                  <div class="mat-tree-node">
                    <button mat-button *ngIf="!node.parentId" class="button unclickable-button">
                      <mat-icon></mat-icon>
                      {{ node.title }}
                    </button>
                  </div>
                </li>
              </mat-tree-node>

              <mat-nested-tree-node *matTreeNodeDef="let node; when: nodeHasChild">
                <li>
                  <div class="mat-tree-node">
                    <button mat-button matTreeNodeToggle class="button mat-drawer-tree-button">
                      <mat-icon>
                        {{processTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                      </mat-icon>
                      {{node.title}}
                    </button>
                  </div>
                  <ul [class.process-drawer-tree-invisible]="!processTreeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
                </li>
              </mat-nested-tree-node>
            </ul>
          </mat-tree>
        </mat-drawer>

        <mat-drawer-content class="mat-drawer-content">
          <div [class.mat-drawer-content-expanded]="processDrawer.opened" class="row">
            <div class="col-sm-10">
              <form id="processGroupForm" #processGroupForm="ngForm" (ngSubmit)="save(processGroupForm)" *ngIf="canEdit() && isEditMode">
                <p>
                  <mat-form-field color="accent">
                    <mat-label>Title</mat-label>
                    <input matInput
                           placeholder="Enter title..."
                           name="title"
                           [(ngModel)]="process.title"
                           #title="ngModel"
                           [disabled]="isBusy"
                           required>
                    <mat-error *ngIf="title.invalid">
                      Title is required
                    </mat-error>
                  </mat-form-field>
                </p>
                <p>
                  <mat-form-field color="accent">
                    <mat-label>Reference</mat-label>
                    <input matInput
                           placeholder="Enter reference..."
                           name="reference"
                           [(ngModel)]="process.reference"
                           [disabled]="isBusy" />
                  </mat-form-field>
                </p>
                <p>
                  <mat-form-field color="accent">
                    <mat-label>Description</mat-label>
                    <textarea matInput
                              placeholder="Enter description..."
                              name="description"
                              [(ngModel)]="process.description"
                              [disabled]="isBusy">
                </textarea>
                  </mat-form-field>
                </p>
                <p class="owner-field">
                  <app-autocomplete [label]="'Owner'"
                                    [placeholder]="'Select a Role'"
                                    [(value)]="process.ownerRoleId"
                                    [options]="allRoles"
                                    [isDisabled]="isBusy"
                                    class="dropdown">
                  </app-autocomplete>
                  <ng-container *ngIf="!isNew &&
                                authService.currentUser!.isEditor &&
                                hasNoUsersAssigned(process.ownerRoleId)">
                    <mat-icon matTooltip="Currently no user assigned to this role"
                              color="accent"
                              class="icon">priority_high</mat-icon>
                  </ng-container>
                </p>
                <p>
                  <app-autocomplete [label]="'Approver'"
                                    [placeholder]="'Select a Role'"
                                    [(value)]="process.approverRoleId"
                                    [options]="allRoles"
                                    [isDisabled]="isBusy">
                  </app-autocomplete>
                </p>
                <p>
                  <app-multi-autocomplete *ngIf="canEdit()"
                                          [label]="'Categories'"
                                          [placeholder]="'Select a Category'"
                                          [options]="categories"
                                          [selectedOptions]="process.categories"
                                          [isDisabled]="isBusy">
                  </app-multi-autocomplete>
                </p>
                <p>
                  <mat-form-field color="accent">
                    <mat-label>Review Due Date</mat-label>
                    <input matInput [matDatepicker]="reviewPicker" [(ngModel)]="process.reviewDate" name="reviewDate" [disabled]="isBusy" />
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="reviewPicker"></mat-datepicker-toggle>
                    <mat-datepicker #reviewPicker></mat-datepicker>
                  </mat-form-field>
                </p>
                <p>
                  <mat-checkbox [(ngModel)]="process.isDraft"
                                [disabled]="isBusy"
                                name="isDraft"
                                class="draft">
                    Draft
                  </mat-checkbox>
                </p>
              </form>

              <div id="{{ printableSectionOneId }}" *ngIf="!isEditMode">
                <div class="readonly-section">
                  <label class="readonly-label">
                    Title
                  </label>
                  <div class="readonly-content">
                    {{ process.title }}
                  </div>
                </div>
                <div class="readonly-section">
                  <label class="readonly-label">
                    Reference
                  </label>
                  <div class="readonly-content">
                    {{ process.reference }}
                  </div>
                </div>
                <div class="readonly-section">
                  <label class="readonly-label">
                    Description
                  </label>
                  <div class="readonly-content">
                    {{ process.description }}
                  </div>
                </div>
                <div class="readonly-section">
                  <label class="readonly-label">
                    Owner
                  </label>
                  <div class="readonly-content">
                    {{ getRoleTitle(process.ownerRoleId) }}
                  </div>
                </div>
                <div class="readonly-section">
                  <label class="readonly-label">
                    Approver
                  </label>
                  <div class="readonly-content">
                    {{ getRoleTitle(process.approverRoleId) }}
                  </div>
                </div>
                <div class="readonly-section">
                  <label class="readonly-label">
                    Categories
                  </label>
                  <div class="readonly-content">
                    <span *ngFor="let category of process.categories; let last = last">
                      {{ category.name }}<span *ngIf="!last">,</span>
                    </span>
                  </div>
                </div>
                <div class="readonly-section">
                  <label class="readonly-label">
                    Review Due Date
                  </label>
                  <div class="readonly-content">
                    {{ process.reviewDate | date: 'dd MMM yyyy' }}
                  </div>
                </div>
                <div class="readonly-section" data-html2canvas-ignore>
                  <div class="readonly-content">
                    <mat-checkbox [(ngModel)]="process.isDraft"
                                  [disabled]="true"
                                  name="isDraft"
                                  class="draft">
                      Draft
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-2">
              <p *ngIf="canEdit()">
                <button mat-flat-button
                        color="accent"
                        class="button accent-button side-button"
                        [disabled]="isNew || isBusy"
                        (click)="toggleView()">
                  Switch To {{ isEditMode ? 'View' : 'Edit' }} Mode
                </button>
              </p>
              <p>
                <button mat-flat-button
                        color="accent"
                        class="button accent-button side-button"
                        (click)="openRevisionsDialog()"
                        [disabled]="isNew || isBusy">
                  Revisions
                </button>
              </p>
              <p>
                <button mat-flat-button
                        color="accent"
                        class="button accent-button side-button"
                        (click)="openStandardsDialog()"
                        [disabled]="isNew || isBusy">
                  Standards
                </button>
              </p>
              <p>
                <button mat-flat-button
                        color="accent"
                        class="button accent-button side-button"
                        (click)="openResourcesDialog()"
                        [disabled]="isNew || isBusy">
                  Resources
                </button>
              </p>
              <p *ngIf="authService.currentUser!.companyHasCustoms">
                <button mat-flat-button
                        color="accent"
                        class="button accent-button side-button"
                        (click)="openCustomsDialog()"
                        [disabled]="isNew || isBusy">
                  Customs
                </button>
              </p>
              <p>
                <span matTooltip="{{ isEditMode ? 'To export, switch to View Mode' : '' }}" matTooltipShowDelay="0" matTooltipPosition="left">
                  <button mat-flat-button
                          color="accent"
                          class="button accent-button side-button"
                          (click)="export()"
                          [disabled]="isNew || isBusy || isEditMode">
                    Export To PDF
                  </button>
                </span>
              </p>
              <p>
                <span matTooltip="{{ isEditMode ? 'To export, switch to View Mode' : '' }}" matTooltipShowDelay="0" matTooltipPosition="left">
                  <button mat-flat-button
                          color="accent"
                          class="button accent-button side-button"
                          (click)="exportExcel()"
                          [disabled]="isNew || isBusy || isEditMode">
                    Export To Excel
                  </button>
                </span>
              </p>
              <p>
                <span matTooltip="{{ isEditMode ? 'To print, switch to View Mode' : '' }}" matTooltipShowDelay="0" matTooltipPosition="left">
                  <button mat-flat-button
                          color="accent"
                          class="button accent-button side-button"
                          (click)="generateChecklistDocument()"
                          [disabled]="isNew || isBusy || isEditMode">
                    Print Checklist
                  </button>
                </span>
              </p>
              <p *ngIf="canEdit()">
                <button mat-flat-button
                        color="accent"
                        class="button accent-button side-button"
                        (click)="copyProcess()"
                        [disabled]="isNew || isBusy || !isEditMode">
                  Copy Process
                </button>
              </p>
              <p *ngIf="canEdit()">
                <button mat-flat-button
                        color="accent"
                        class="button accent-button side-button"
                        type="submit"
                        [disabled]="isBusy || !isEditMode"
                        form="processGroupForm">
                  Save Details
                </button>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <button mat-icon-button color="accent"
                      matTooltip="{{ processDrawer.opened ? 'Hide Processes' : 'Show Processes' }}"
                      (click)="processDrawer.toggle()">
                <mat-icon>{{ processDrawer.opened ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right' }}</mat-icon>
              </button>
            </div>
          </div>

          <div *ngIf="!isNew" class="row">
            <div class="col-sm-12">
              <div id="{{ printableSectionTwoId }}">
                <table mat-table [dataSource]="tableData">
                  <ng-container matColumnDef="sequence">
                    <th mat-header-cell *matHeaderCellDef class="raci-header">Seq</th>
                    <td mat-cell *matCellDef="let processTask">{{ processTask.headerSequenceNumber }}.{{ processTask.subSequenceNumber }}</td>
                  </ng-container>

                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef class="raci-header">Title</th>
                    <td mat-cell *matCellDef="let processTask" class="pre-swimlane-column">
                      <button *ngIf="isEditMode" mat-button (click)="editProcessTask(processTask)" class="button table-button">
                        <span [ngClass]="{'header-task-title' : processTask.isHeader}">{{processTask.title}}</span>
                      </button>
                      <div *ngIf="!isEditMode" [ngClass]="{'header-task-title' : processTask.isHeader}">{{processTask.title}}</div>
                    </td>
                  </ng-container>

                  <ng-container *ngFor="let role of roles" matColumnDef="{{role.title}}">
                    <th mat-header-cell *matHeaderCellDef class="role-header">
                      <div><span>{{role.title}}</span></div>
                    </th>
                    <td mat-cell
                        *matCellDef="let processTask"
                        class="swimlane-cell"
                        [style.background-color]="getSwimlaneColor()"
                        [matTooltip]="getRaciTooltip(processTask, role)">
                      <div [ngClass]="getRoleClass(processTask, role)">
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="raci-header">Description</th>
                    <td mat-cell *matCellDef="let processTask" class="post-swimlane-column">
                      <div class="description-cell">
                        <p *ngIf="processTask.descriptionFrom">
                          <strong>{{processTask.descriptionFrom}}</strong>
                        </p>
                        <p *ngIf="processTask.description">
                          {{processTask.description}}
                        </p>
                        <p *ngIf="processTask.isMilestone">
                          <strong>Milestone</strong>
                          <span *ngIf="processTask.milestoneReason != null && processTask.milestoneReason != ''">
                            <br />
                            Reason: {{ processTask.milestoneReason }}
                          </span>
                          <br />
                        </p>
                        <p *ngIf="processTask.processTaskDocuments.length > 0">
                          <span *ngFor="let document of processTask.processTaskDocuments; last as isLast">
                            <a *ngIf="document.documentLink && !document.fileDocument" class="raci-link" target="_blank" href="{{document.documentLink}}">
                              {{ document.typeDescription }} {{ document.documentName }}
                            </a>
                            <a *ngIf="!document.documentLink && document.fileDocument" href="javascript:void(0)" (click)="downloadDocument(document)">
                              {{ document.typeDescription }} {{ document.documentName }}
                            </a>
                            <span *ngIf="(document.documentLink && document.fileDocument) || (!document.documentLink && !document.fileDocument)">
                              {{ document.typeDescription }} {{ document.documentName }}
                            </span>
                            <mat-icon matTooltip="Open Document"
                                      (click)="openDocument(document.documentId)"
                                      class="info-icon"
                                      data-html2canvas-ignore="true"
                                      inline="true"
                                      color="accent">info</mat-icon>
                            <br *ngIf="!isLast" />
                          </span>
                        </p>
                        <p *ngIf="processTask.processTaskProcesses.length > 0">
                          <span *ngFor="let subProcess of processTask.processTaskProcesses; last as isLast">
                            <i>Sub-Process: </i> <a routerLink="/{{authService.currentUser!.companyName}}/processes/{{subProcess.processProcessGroupId}}/{{subProcess.processId}}" class="raci-link">{{ subProcess.displayName }}</a>
                            <br *ngIf="!isLast" />
                          </span>
                        </p>
                        <p *ngIf="processTask.processTaskCustoms.length > 0">
                          <span *ngFor="let custom of processTask.processTaskCustoms; last as isLast">
                            {{custom.customDescription}}
                            <br *ngIf="!isLast"/>
                          </span>
                        </p>
                        <p *ngIf="processTask.processTaskResources.length > 0">
                          <span *ngFor="let resource of processTask.processTaskResources; last as isLast">
                            {{resource.resourceDescription}}
                            <br *ngIf="!isLast" />
                          </span>
                        </p>
                        <p *ngIf="processTask.processTaskStandardSections.length > 0">
                          <span *ngFor="let section of processTask.processTaskStandardSections; last as isLast">
                            {{ section.standardDisplayName }} - {{ section.standardSectionDisplayName }}
                            <br *ngIf="!isLast" />
                          </span>
                        </p>
                        <p *ngIf="processTask.descriptionTo">
                          <strong>
                            {{processTask.descriptionTo}} {{processTask.descriptionReason}}
                          </strong>
                        </p>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="controls">
                    <th mat-header-cell *matHeaderCellDef class="raci-header"></th>
                    <td mat-cell *matCellDef="let processTask" class="mat-cell-end">
                      <button mat-icon-button color="accent" *ngIf="!isFirstProcessTask(processTask)" (click)="moveProcessTaskUp(processTask)" matTooltip="Move Up" [disabled]="isBusy">
                        <mat-icon>arrow_upward</mat-icon>
                      </button>
                      <button mat-icon-button color="accent" *ngIf="!isLastProcessTask(processTask)" (click)="moveProcessTaskDown(processTask)" matTooltip="Move Down" [disabled]="isBusy">
                        <mat-icon>arrow_downward</mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="deleteProcessTask(processTask)" matTooltip="Delete" [disabled]="isBusy">
                        <mat-icon>delete</mat-icon>
                      </button>
                      <button mat-icon-button color="accent" (click)="branchProcessTask(processTask)" matTooltip="Branch" [disabled]="isBusy">
                        <mat-icon>link</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="footer">
                    <td mat-footer-cell *matFooterCellDef colspan="2">
                      <button mat-flat-button color="accent" class="button accent-button" (click)="addProcessTask()" [disabled]="isBusy || !isEditMode">Add Task</button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columns; sticky: true;"></tr>
                  <tr mat-row *matRowDef="let processTask; columns: columns;" [ngClass]="showRowHighlight ? getRowClass(processTask) : ''"></tr>
                  <tr mat-footer-row [hidden]="!isEditMode" *matFooterRowDef="['footer']"></tr>
                </table>

                <div class="raci-legend">
                  <span class="raci-icon responsible-triangle raci-legend-key"> Responsible</span>
                  <span class="accountable-square raci-square raci-legend-key"> {{ authService.currentUser!.companyAccountableText }}</span>
                  <span class="consults-square raci-square raci-legend-key"> Consults</span>
                  <span class="informed-square raci-square raci-legend-key"> Informed</span>
                  <span class="raci-icon branch-to-diamond raci-legend-key"> Branch To</span>
                  <span class="raci-icon milestone-star raci-legend-key"> Milestone</span>
                </div>
              </div>
            </div>
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </mat-card-content>
</mat-card>

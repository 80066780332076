import { CompanyModel } from "./company-model";

export class UserModel {
  public userId: number;
  public companyId: number;
  public companyName: string;
  public companyDisplayName: string;
  public companyAccountableText: string;
  public companyHasCustoms: boolean = false;
  public companyIsLogoShownOnNavbar: boolean = false;
  public emailAddress: string;
  public isVerified: boolean = false;
  public isAdministrator: boolean = false;
  public isEnabled: boolean = false;
  public isCompanyAdministrator: boolean = false;
  public isEditor: boolean = false;
  public isApprover: boolean = false;
  public displayName: string;
  public isLockedOut: boolean = false;
  public isPrismStaff: boolean = false;
  public companySwimLaneColor: string;

  public hasCompanyBeenSelected: boolean = false;
  public companies: Array<CompanyModel>;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

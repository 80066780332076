import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { ConfigurationService } from './services/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'Author';
  public currentRoute: string;

  constructor(
    public configurationService: ConfigurationService,
    public authService: AuthService,
    private router: Router) {
  }

  public get isTest(): boolean {
    return environment.instance === "test";
  }

  public signOut(): void {
    this.authService.clearAuthentication();
    this.router.navigate(["/sign-in"]);
  }
}

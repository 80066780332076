import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentModel } from '../../../models/document-model';
import { DocumentService } from '../../../services/document.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProcessModel } from '../../../models/process-model';
import { Router } from '@angular/router';
import { ProcessService } from '../../../services/process.service';
import { RoleService } from '../../../services/role.service';
import { RoleModel } from '../../../models/role-model';
import { RoleTitleUtility } from '../../../utility/role-title-utility';
import { CategoryModel } from '../../../models/category-model';
import { CategoryService } from '../../../services/category.service';
import { Observable, forkJoin } from 'rxjs';
import { FileDocumentMetadataModel } from '../../../models/file-document-metadata-model';
import { FileDocumentUtility } from '../../../utility/file-document-utility';
import { FileDocumentService } from '../../../services/file-document-service';
import { ValidationService } from '../../../services/validation-service';
import { NgForm } from '@angular/forms';
import { ProcessAssociationModel } from '../../../models/process-association-model';

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss']
})
export class DocumentDialogComponent {
  public model: DocumentModel;
  public dialogTitle: string = "New Document";
  public documents: Array<DocumentModel> = [];
  public processesTableData = new MatTableDataSource<ProcessAssociationModel>([]);
  public processesColumns: string[] = ['title', 'association'];
  public roles: Array<RoleModel> = [];
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public categories: Array<CategoryModel> = [];
  public documentFileName: string | null;
  public documentFormData: FormData | null;

  private processSort: MatSort;

  @ViewChild('documentProcessesTable', { read: MatSort, static: false }) set processSortValue(value: MatSort) {
    if (value) {
      this.processSort = value;
      this.processesTableData.sort = this.processSort;
    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) private document: DocumentModel,
    private dialogRef: MatDialogRef<DocumentDialogComponent>,
    private documentService: DocumentService,
    private toastr: ToastrService,
    private authService: AuthService,
    private processService: ProcessService,
    private roleService: RoleService,
    private router: Router,
    private categoryService: CategoryService,
    private fileDocumentService: FileDocumentService,
    private validationService: ValidationService,) {
    this.model = structuredClone(document);

    if (!this.isNew) {
      this.dialogTitle = this.model.documentName;
    }
  }

  public ngOnInit(): void {
    let sources: Array<Observable<any>> = [
      this.roleService.getRoles(),
      this.categoryService.getCategories(),
    ];

    if (!this.isNew) {
      sources.push(this.processService.getDocumentProcesses(this.model.documentId));
    }

    forkJoin(sources).subscribe({
      next: (response) => {
        this.roles = response[0] as RoleModel[];
        this.categories = response[1] as CategoryModel[];
        if (!this.isNew) {
          this.processesTableData.data = response[2] as ProcessAssociationModel[];
        }
        this.isLoading = false;
      }
    });
  }

  public get isNew(): boolean {
    return this.model == null ||
      this.model.documentId == null ||
      this.model.documentId == undefined ||
      this.model.documentId == 0;
  }

  public get processesTabTitle(): string {
    return `Associated Processes (${this.processesTableData.data.length})`;
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.model.link && (this.model.fileDocumentId || this.documentFormData)) {
      this.toastr.error("Document could not be saved as both a document link and file have been provided.");
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      if (this.model.reviewDueDate != null) {
        var timeZoneOffset = this.model.reviewDueDate!.getTimezoneOffset() * 60000;
        this.model.reviewDueDate = new Date(this.model.reviewDueDate!.getTime() - timeZoneOffset);
      }

      this.documentService.saveDocument(this.model).subscribe({
        next: (response: DocumentModel) => {
          this.model = response;
          if (this.documentFormData) {
            this.documentService.uploadDocumentFile(this.model.documentId, this.documentFormData).subscribe({
              next: (response: FileDocumentMetadataModel | null) => {
                this.model.fileDocument = response;
                this.toastr.success("Document saved");
                this.dialogRef.close(this.model);
              },
              error: () => {
                this.toastr.error(`Error uploading ${this.documentFileName ? this.documentFileName : "Document"}`);
                this.isBusy = false;
              }
            });
          } else {
            this.toastr.success("Document saved");
            this.dialogRef.close(this.model);
          }
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public openProcess(process: ProcessModel) {
    this.close();
    this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${process.processGroupId}/${process.processId}`);
  }

  public close() {
    this.dialogRef.close();
  }

  public getRoleTitle(roleId: number | null): string | null {
    return RoleTitleUtility.getRoleTitle(this.roles, roleId);
  }

  public downloadFile() {
    if (this.model.fileDocument) {
      this.fileDocumentService.getFileDocument(this.model.fileDocument).subscribe({
        next: (response: any) => {
          FileDocumentUtility.openFileDocument(response);
        }
      });
    }
  }

  public uploadFile(files: FileList | null) {
    if (!this.canEdit || !files || files.length != 1) {
      return;
    }

    if (this.model.link) {
      this.toastr.error("Cannot upload document while a link is defined");
      return;
    }

    let fileToUpload = files[0] as File;

    if (fileToUpload.name.includes(";")) {
      this.toastr.error("File name cannot contain the ; character");
      return;
    }

    let formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    this.documentFileName = fileToUpload.name;
    this.documentFormData = formData;
  }

  public deleteFile() {
    if (!this.canEdit) {
      return;
    }

    this.documentFileName = null;
    this.documentFormData = null;
    this.model.fileDocumentId = null;
    this.model.fileDocument = null;
  }
}
